<template>
  <div class="degreeExercises">
    <div class="search_sList">
      <div class="top_opera">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          label-width="80px"
        >
          <el-form-item label="题库名称">
            <el-input
              v-model="formInline.title"
              placeholder="题库名称"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn_area">
          <el-button
            class="mainBtn"
            @click="onSubmit"
            plain
            v-preventReClick
            >搜索</el-button
          >
          <el-button type="info" @click="resetEvent" plain>重置</el-button>
        </div>
      </div>
    </div>
    <div class="table_sList">
      <div class="opera_btnArea">
        <el-button class="mainBtn" @click="addEvent" plain>添加题库</el-button>
      </div>
      <el-table
        :data="tableData"
        stripe
        border
        style="width: 100%"
        align="center"
      >
        <el-table-column
          prop="questionBankName"
          label="题库名称"
          align="center"
        >
          <template slot-scope="scope">
            <el-tooltip
              :content="scope.row.questionBankName"
              placement="top"
              effect="light"
              popper-class="pubToop"
            >
              <div class="ellipsis">{{ scope.row.questionBankName || '/' }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="questionCount"
          label="题目数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="100"
          align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="deleteEvent(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <Pagination
        @changePage="changePage"
        :childMsg="childMsg"
      ></Pagination> -->
    </div>

    <dialogDiyNew :isShow="isShowSpecial" :dialogDiyData="dialogSpecialDiyData"
    @dialogEvent="isShowSpecial = false">
      <div slot="dialogContent" class="special_area">
        <div class="man-title" v-if="selectData.length != 0">已添加题库：</div>
        <div class="select-area">
          <div class="manage" v-for="(item, index) in selectData" :key="index" @mousemove="showEvent(index, 'over')" @mouseleave="showEvent(index, 'leave')">
            <img class="manClose" v-show="selectData[index].isShow" src="../../../assets/img/man-close.png" alt="" @click="byDeleteEvent(index)">
            <span>{{item.resName || '/'}}</span>
          </div>
        </div>
        <div class="search_sList">
          <div class="top_opera">
            <el-form
              :inline="true"
              :model="specialForm"
              class="demo-form-inline"
              label-width="80px"
            >
              <el-form-item label="题库名称">
                <el-input
                  v-model="specialForm.title"
                  placeholder="题库名称"
                  clearable
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="btn_search_area">
              <el-button
                class="mainBtn"
                @click="onSubmitEvent"
                plain
                v-preventReClick
                >搜索</el-button
              >
              <el-button type="info" @click="resetOpera" plain>重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_list_new">
          <el-table
            :data="addTableData"
            stripe
            border
            style="width: 100%"
            align="center"
            max-height="400"
          >
            <el-table-column
              prop="questionBankName"
              label="题库名称"
              align="center"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.questionBankName"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.questionBankName || '/' }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="questionCount"
              label="题目数量"
              align="center"
              width="80"
            >
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="100"
              align="center">
              <template slot-scope="scope">
                <el-button :disabled="checkAdd(scope.row)" type="text" @click="addSpecial(scope.row)">{{checkAdd(scope.row) ? '已添加' : '添加'}}</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- <Pagination
            @changePage="changePage"
            :childMsg="childMsg"
          ></Pagination> -->
        </div>
        <div class="btn_area">
          <el-button @click="isShowSpecial = false">取消</el-button>
          <el-button type="primary" @click="enterEvent">确认</el-button>
        </div>
      </div>
    </dialogDiyNew>

    <dialogDiy
      :isShow="isShowDel"
      :dialogDiyData="dialogDelDiyData"
      @dialogEvent="isShowDel = false"
    >
      <div slot="dialogContent" class="dia_opera">
        <img class="errIcon" src="../../../assets/img/warn.png" alt="" />
        <p>确定要删除该题库吗？</p>
        <div class="btn_area">
          <el-button @click="isShowDel = false">关闭</el-button>
          <el-button class="mainBtn" @click="doEvent">确定</el-button>
        </div>
      </div>
    </dialogDiy>
  </div>
</template>

<script>
import merge from 'webpack-merge';
import Pagination from '../../Pagination'
import dialogDiy from '../../commonModule/dialogDiy'
import dialogDiyNew from '../../commonModule/dialogDiyNew'
export default {
  name: 'degreeExercises',
  components: {
    Pagination,
    dialogDiy,
    dialogDiyNew
  },
  data () {
    return {
      formInline: {
        sourceType: 0,
        title: null
      },
      tableData: [],
      addTableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1,
      },
      isShowSpecial: false,
      dialogSpecialDiyData: {
        title: '添加题库',
        sizeStyle: {width: '800px',height: '700px'}
      },
      specialForm: {
        courseId: null,
        courseName: null,
        ids: null,
        sourceType: 0,
        title: null
      },
      selectData: [],
      isShowDel: false,
      dialogDelDiyData: {
        title: null,
        sizeStyle: {width: '400px',height: '234px'}
      },
      rowData: null,
      specialIdsArr: []
    }
  },
  created() {
    // this.formInline.ids = this.$route.query.ids
    this.ykSpecialListEvent()
  },
  methods: {
    // 搜索
    onSubmit() {
      this.ykSpecialListEvent();
    },
    // 重置
    resetEvent() {
      this.formInline.courseId = null
      this.formInline.courseName = null
      this.formInline.title = null
    },
    // 搜索
    onSubmitEvent() {
      this.getYkSpecialList();
    },
    // 重置
    resetOpera() {
      this.specialForm.courseId = null
      this.specialForm.courseName = null
      this.specialForm.title = null
    },
    addEvent() {
      this.selectData = []
      this.isShowSpecial = !this.isShowSpecial
      this.getYkSpecialList()
    },
    getYkSpecialList() {
      let path = { foreignClassId: this.$route.query.foreignClassId }
      this.$request.classDegreeExercises(this.specialForm, path).then(res => {
        if (res.data.code == 0) {
          // let { records, current, size, total } = res.data.data;
          this.addTableData = res.data.data.banks
          this.specialIdsArr = res.data.data.ids.split(',')
          // this.childMsg = {
          //   current,
          //   size,
          //   total
          // };
        }
      });
    },
    // 获取表格数据
    ykSpecialListEvent() {
      let path = { foreignClassId: this.$route.query.foreignClassId }
      this.$request.selectedClassDegreeExercises(this.formInline, path).then(res => {
        if (res.data.code == 0) {
          // let { records, current, size, total } = res.data.data;
          this.tableData = res.data.data.banks
          this.specialIdsArr = res.data.data.ids.split(',')
          // this.childMsg = {
          //   current,
          //   size,
          //   total
          // };
        }
      });
    },
    // changePage(val) {
    //   this.childMsg = val;
    //   this.addTableData.page = val.current;
    //   this.addTableData.pageSize = val.size;
    //   this.getYkSpecialList();
    // },
    addSpecial(row) {
      this.selectData.push({
        "resName": row.questionBankName,
        "foreignClassId": this.$route.query.foreignClassId,
        // "imgUrl": row.imgUrl,
        "resId": row.id,
        "type": 2
      });
    },
    checkAdd(row) {
      let index = this.selectData.findIndex(item => item.resId == row.id)
      let isIndex = this.specialIdsArr.findIndex(item => item == row.id)
      if(index > -1 || isIndex > -1)
        return true;
      else
        return false;
    },
    byDeleteEvent(index) {
      this.selectData.splice(index, 1);
    },
    showEvent(index, type) {
      if(type == 'over')
        this.$set(this.selectData[index], 'isShow', true)
      else
        this.$set(this.selectData[index], 'isShow', false)
      this.$forceUpdate()
    },
    deleteEvent(row) {
      this.isShowDel = !this.isShowDel;
      this.rowData = row;
    },
    enterEvent() {
      if(this.selectData.length == 0) {
        this.Warn('请选择题库');
        return;
      }
      // let ids = []
      // let resList = []
      // this.selectData.forEach(item => {
      //   ids.push(item.id)
      //   resList.push({
      //     courseId: item.courseId,
      //     resId: item.id
      //   })
      // })
      // let idss = this.formInline.ids ? this.formInline.ids + ',' : ''
      // let nIds = idss + ids.join(',')
      // const items = Array.from(new Set(nIds.split(',')));
      // this.formInline.ids = items.join(',');
      // let dto = {
      //   aidClassId: this.$route.query.aidClassId,
      //   resList
      // }
      this.$request.addClassDegreeResources(this.selectData).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg || '操作成功')
          this.ykSpecialListEvent()
          this.isShowSpecial = !this.isShowSpecial
          this.clearAddEvent()
          // this.$router.push({
          //   query:merge(this.$route.query,{'ids': this.formInline.ids})
          // })
        }
      })
    },
    doEvent() {
      let obj = {
        foreignClassId: this.$route.query.foreignClassId,
        resId: this.rowData.id,
        type: 2
      }
      this.$request.deleteClassDegreeResources(obj).then(res => {
        if(res.data.code == 0) {
          // this.reduceIds(this.rowData.id)
          this.Success(res.data.msg);
          this.isShowDel = !this.isShowDel;
          this.ykSpecialListEvent();
        }
      })
    },
    reduceIds(id) {
      let idArr = this.formInline.ids.split(',')
      idArr.forEach((item, index) => {
        if(item == id) idArr.splice(index, 1)
      })
      this.formInline.ids = idArr.join(',')
      this.$router.push({
        query:merge(this.$route.query,{'ids': this.formInline.ids})
      })
    },
    clearAddEvent() {
      // this.dialogSpecialDiyData.sizeStyle.height = '750px'
      this.selectData = []
      this.resetOpera()
    }
  }
}
</script>

<style lang='less'>
  .degreeExercises {
    .man-title {
      font-size: 16px;
      color: #333;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .select-area {
      display: flex;
      flex-wrap: wrap;
      .manage {
        position: relative;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: #1A7EE2;
        background-color: #a3cbf3;
        cursor: pointer;
        padding: 6px 10px;
        text-align: center;
        margin-left: 6px;
        margin-top: 6px;
        .manClose {
          position: absolute;
          top: -5px;
          right: -5px;
          width: 12px;
          height: 12px;
        }
      }
    }
    .search_sList {
      background-color: #fff;
      padding: 28px 40px;
      .top_opera {
        display: flex;
        .el-form {
          flex: 4;
          .el-form-item__content {
            width: 218px;
          }
        }
        .btn_area, .btn_search_area {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .table_sList {
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 10px;
      background-color: #fff;
      .opera_btnArea {
        margin-bottom: 20px;
      }
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
          background-color: rgba(0, 0, 0, .1);
          border-radius: 3px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
      }
      .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
          border-right: none;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
      }
      .el-icon-arrow-down {
        font-size: 12px;
      }
      .el-pagination {
        text-align: right;
      }
    }
    .table_list_new {
      padding: 0;
      .el-pagination {
        text-align: right;
      }
    }
    .special_area {
      padding: 30px;
      box-sizing: border-box;
      .btn_area {
        margin-top: 6px;
        text-align: right;
      }
    }
  }
</style>